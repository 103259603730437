import Repository from './repository'

const resource = '/linha'

export default {

  getId (id) {
    return Repository.get(`${resource}/${id}`)
  },
  getList (pesquisa) {
    return Repository.get(`${resource}`)
  },
  Update (data) {
    return Repository.post(`${resource}`, data)
  },
  Delete (data) {
    return Repository.post(`${resource}`, data)
  },
  Save (data) {
    return Repository.post(`${resource}`, data)
  },
}
