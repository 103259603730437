import Repository from './repository'

const resource = '/produtos'

export default {

  getId (id) {
    return Repository.get(`${resource}/1/${id}`)
  },
  async getList () {
    return await Repository.get(`${resource}/getlist/1`)
  },
  async getCode (loja, code) {
    return await Repository.get(`${resource}/${loja}/${code}`)
  },
  async getDesc (loja, desc) {
    return await Repository.get(`${resource}/getlist/${loja}/${desc}`)
  },
  async getContendo (loja, desc) {
    return await Repository.get(`${resource}/getlistContendo/${loja}/${desc}`)
  },
  Update (data) {
    return Repository.post(`${resource}/editar/`, data)
  },
  Delete (data) {
    return Repository.post(`${resource}/excluir/`, data)
  },
  Save (data) {
    return Repository.post(`${resource}/save/`, data)
  },
}
