<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-card>
      <v-card-title>
        Posição Físico
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar"
          single-line
          hide-details
        >
          <template v-slot:append>
            <v-tooltip
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  medium
                  color="primary"
                  class="mt-0 pt-0"
                  @click="dialogCodgiBarras = true"
                >
                  <v-icon v-on="on">
                    mdi-barcode
                  </v-icon>
                </v-btn>
              </template>
              Pesquisar
            </v-tooltip>
            <v-tooltip
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  medium
                  color="primary"
                  class="mt-0 pt-0"
                  @click="dialogEtiqueta = true"
                >
                  <v-icon v-on="on">
                    mdi-printer
                  </v-icon>
                </v-btn>
              </template>
              Pesquisar
            </v-tooltip>
          </template>
        </v-text-field>
      </v-card-title>
      <v-row class="ml-1">
        <v-col
          cols="12"
          md="2"
        >
          <componenteLojaPos
            :id="loja"
            dense
            :flparamentrovenda="false"
            @onSelectLoja="onSelectLoja"
          />
        </v-col>
      </v-row>
      <v-data-table
        dense
        :headers="headers"
        :items="listaPosicaoFisico"
        :search="search"
      >
        <template v-slot:item.vlPesoUnd="{ item }">
          {{ formatPrice(item.vlPesoUnd) }}
        </template>
        <template v-slot:item.qtEstoque="{ item }">
          {{ formatPrice(item.qtEstoque) }}
        </template>
        <template v-slot:item.qtReservado="{ item }">
          {{ formatPrice(item.qtReservado) }}
        </template>
        <template v-slot:item.qtDisponivel="{ item }">
          {{ formatPrice(item.qtDisponivel) }}
        </template>
        <template v-slot:item.qtEstoqueMin="{ item }">
          {{ formatPrice(item.qtEstoqueMin) }}
        </template>
        <template v-slot:item.qtADevolver="{ item }">
          {{ formatPrice(item.qtADevolver) }}
        </template>
        <template v-slot:item.qtReal="{ item }">
          {{ formatPrice(item.qtReal) }}
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="dialogCodgiBarras"
      fullscreen
    >
      <modalLeituraCodBarra @onPesquisaCodigoBarras="onPesquisaCodigoBarras" />
    </v-dialog>
    <v-dialog
      v-if="dialogEtiqueta"
      v-model="dialogEtiqueta"
      max-width="800"
    >
      <modalEtiqueta />
    </v-dialog>
  </v-container>
</template>
<script>
  import posicaofisicoRep from './../../components/services/posicaofisicoRepository.js'
  import transacaoRep from './../../components/services/transacaoRepository'
  import modalLeituraCodBarra from './../componentes/componente-Leitura-CodigoBarra'
  import modalEtiqueta from './../componentes/modal-etiqueta.vue'
  import componenteLojaPos from '../componentes/componente-loja.vue'

  export default {
    components: {
      modalLeituraCodBarra,
      modalEtiqueta,
      componenteLojaPos,
    },
    data () {
      return {
        search: '',
        listaPosicaoFisico: [],
        headers: [
          {
            text: 'Produto',
            align: 'start',
            value: 'descProduto',
            width: 320,
          },
          {
            text: 'Unid.',
            align: 'center',
            value: 'cdUnidade',
            width: 75,
          },
          {
            text: 'Peso Und',
            align: 'end',
            value: 'vlPesoUnd',
            width: 100,
          },
          {
            text: 'Estoque',
            align: 'end',
            value: 'qtEstoque',
            width: 100,
          },
          {
            text: 'Reservado',
            align: 'end',
            value: 'qtReservado',
            width: 100,
          },
          {
            text: 'Disponível',
            align: 'end',
            value: 'qtDisponivel',
            width: 100,
          },
          {
            text: 'Minimo',
            align: 'end',
            value: 'qtEstoqueMin',
            width: 100,
          },
          {
            text: 'Inat. Item',
            align: 'center',
            value: 'descFlInativo',
            width: 15,
          },
          {
            text: 'Inat. Loja',
            align: 'center',
            value: 'descFlInativoLoja',
            width: 15,
          },
          {
            text: 'A Devolver',
            align: 'end',
            value: 'qtADevolver',
            width: 100,
          },
          {
            text: 'Real',
            align: 'end',
            value: 'qtReal',
            width: 100,
          },
          {
            text: 'Cod. Barra',
            align: 'end',
            value: 'cdBarra',
            width: 100,
          },
          { text: '', value: 'actions', width: 100, sortable: false },
        ],
        acessoLiberado: false,
        dialogCodgiBarras: false,
        dialogEtiqueta: false,
        loja: 0,
      }
    },
    /* async created () {
      if (this.$store.getters.usuario.tpusuario !== 'A') {
        this.$swal({
          icon: 'warning',
          text: 'Acesso não liberado!',
          confirmButtonText: 'OK',
        }).then((result) => {
          if (result.isConfirmed) this.$router.replace('/v1')
        })
      } else {
        await this.getList()
      }
    }, */
    // async created () {
    //   if (this.$store.getters.usuario.tpusuario !== 'A') {
    //     debugger
    //     if (await this.getTransacao()) {
    //       debugger
    //       await this.getList()
    //     }
    //   } else {
    //     await this.getList()
    //   }
    // },
    async mounted () {
      if (this.$store.getters.usuario.tpusuario !== 'A') {
        await this.getTransacao()
        if (this.acessoLiberado === false) {
          this.$router.replace('/v1')
        }
      }
    },
    methods: {
      async getList () {
        try {
          this.$store.commit('setSplashScreen', true)
          this.listaPosicaoFisico = []
          await posicaofisicoRep.getList(this.loja).then(response => {
            if (response.data.status === 'sucesso') {
              this.$store.commit('setSplashScreen', false)
              this.listaPosicaoFisico = response.data.dados
            } else if (response.data.status === 'mensagem') {
              this.$store.commit('setSplashScreen', false)
              this.$swal({
                icon: 'warning',
                text: response.data.dados,
              })
            } else if (response.data.status === 'error') {
              this.$store.commit('setSplashScreen', false)
              this.$swal({
                icon: 'error',
                text: response.data.messagem,
              })
            } else {
              this.$swal({
                icon: 'error',
                text: response.data.mensagemErro,
              })
              this.$store.commit('setSplashScreen', false)
            }
          }).catch(error => {
            this.$swal({
              icon: 'error',
              text: error,
            })
          })
        } catch (error) {

        }
      },
      async getTransacao () {
        this.$store.commit('setSplashScreen', true)
        var dados = {
          usuario: this.$store.getters.usuario.cdusuario,
          transacao: 'FR035FIS',
        }
        await transacaoRep.get(dados).then(response => {
          if (response.data.status === 'mensagem') {
            this.$store.commit('setSplashScreen', false)
            this.$swal({
              icon: 'warning',
              text: response.data.dados,
              confirmButtonText: 'OK',
            }).then((result) => {
              this.acessoLiberado = false
              if (result.isConfirmed) {
                return false
              }
            })
          } else if (response.data.status === 'error') {
            this.$store.commit('setSplashScreen', false)
            this.$swal({
              icon: 'error',
              text: response.data.messagem,
              confirmButtonText: 'OK',
            }).then((result) => {
              this.acessoLiberado = false
              if (result.isConfirmed) return false
            })
          } else {
            this.$store.commit('setSplashScreen', false)
            this.acessoLiberado = true
            return true
          }
        }).catch(error => {
          this.$store.commit('setSplashScreen', false)
          // this.$swal({
          //   icon: 'error',
          //   text: error,
          // })
          this.$swal({
            icon: 'error',
            text: error,
            confirmButtonText: 'OK',
          }).then((result) => {
            if (result.isConfirmed) return false
          })
        })
      },
      formatPrice (value) {
        var val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },

      onPesquisaCodigoBarras (codigoBarras) {
        // console.log(codigoBarras)
        this.dialogCodgiBarras = false
        this.search = codigoBarras
      },
      onSelectLoja (value) {
        // console.log(value)
        this.loja = value.cdLoja
        this.getList()
      },
    },
  }
</script>
<style>
  .v-data-table>.v-data-table__wrapper>table>tbody>tr>td{
    font-size:8pt;
  }
</style>
