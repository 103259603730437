import Repository from './repository'

const resource = '/grupo'

export default {

  getId (id) {
    return Repository.get(`${resource}/${id}`)
  },
  getLinha (linha) {
    return Repository.get(`${resource}?linha=${linha}`)
  },
  Update (data) {
    return Repository.post(`${resource}`, data)
  },
  Delete (data) {
    return Repository.post(`${resource}`, data)
  },
  Save (data) {
    return Repository.post(`${resource}`, data)
  },
}
