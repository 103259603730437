<template>
  <v-card>
    <v-container
      id="dashboard"
      fluid
      tag="section"
    >
      <v-toolbar
        dark
        color="primary"
      >
        <v-toolbar-title>
          Relatorio de Etiqueta
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          class="mx-2"
          small
          fab
          color="blue"
          @click="gerarPDF"
        >
          <v-icon class="white--text">
            mdi-printer
          </v-icon>
        </v-btn>
        <v-btn
          class="mx-2"
          small
          fab
          color="blue"
          @click="onLimparDados"
        >
          <v-icon class="white--text">
            mdi-eraser
          </v-icon>
        </v-btn>
        <v-btn
          class="mx-2"
          small
          fab
          color="blue"
        >
          <v-icon class="white--text">
            mdi-exit-to-app
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <componenteLoja
            v-if="carregarDados"
            :id="loja"
            :autofocus="true"
            :flparamentrovenda="false"
            @onSelectLoja="onSelectLoja"
          />
          <v-progress-circular
            v-else
            indeterminate
            color="primary"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-select
            v-if="carregarDados"
            v-model="cdLinha"
            :items="listaLinha"
            item-text="nmLinha"
            item-value="cdLinha"
            label="Linha"
            outlined
            hide-details
            @change="onSelectLinha"
          />
          <v-progress-circular
            v-else
            indeterminate
            color="primary"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-select
            v-if="carregarDados"
            v-model="cdGrupo"
            :items="listaGrupo"
            item-text="nmGrupo"
            item-value="cdGrupo"
            label="Grupo"
            outlined
            hide-details
          />
          <v-progress-circular
            v-else
            indeterminate
            color="primary"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-if="carregarDados"
            v-model="paramconsulta"
            label="Item"
            outlined
            hide-details
            :disabled="flitem"
            @keydown.enter="onPesquisaProduto"
          >
            <template v-slot:append>
              <v-tooltip
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    medium
                    color="primary"
                    @click="onPesquisaProduto"
                  >
                    <v-icon v-on="on">
                      mdi-account-search-outline
                    </v-icon>
                  </v-btn>
                </template>
                Pesquisar
              </v-tooltip>
            </template>
          </v-text-field>
          <v-progress-circular
            v-else
            indeterminate
            color="primary"
          />
        </v-col>
        <v-col
          cols="12"
          md="9"
          class="d-flex align-center"
        >
          <strong v-if="carregarDados">{{ nmItem }} </strong>
          <v-progress-circular
            v-else
            indeterminate
            color="primary"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
  import linhaRep from '../../components/services/linhaRepository'
  import grupoRep from '../../components/services/grupoRepository'
  import produtoRep from '../../components/services/produtoRepository'
  import posicaoFisicoRep from '../../components/services/posicaofisicoRepository'
  import componenteLoja from '../componentes/componente-loja.vue'
  // import axios from 'axios'

  export default {
    components: {
      componenteLoja,
    },
    data () {
      return {
        cdLinha: null,
        cdGrupo: 0,
        listaLinha: null,
        listaGrupo: null,
        paramconsulta: null,
        loja: 0,
        flitem: false,
        carregarDados: true,
        flcarregarparamentro: false,
        nmItem: '',
        urlbase: '',
        nmpdf: '',
      }
    },
    created () {
      this.onLimparDados()
      this.get()
    },
    // mounted () {
    //   setTimeout(() => {
    //     this.$refs.cdLoja.focus()
    //   }, 500)
    // },
    methods: {
      async get () {
        try {
          this.$store.commit('setSplashScreen', true)
          await linhaRep.getList().then(response => {
            this.$store.commit('setSplashScreen', false)
            this.listaLinha = response.data
          }).catch(error => {
            this.$swal({
              icon: 'error',
              text: error,
            })
          })
        } catch (error) {
          this.$swal({
            icon: 'error',
            text: error,
          })
        }
      },
      async getGrupo () {
        try {
          this.$store.commit('setSplashScreen', true)
          await grupoRep.getLinha(this.cdLinha).then(response => {
            this.$store.commit('setSplashScreen', false)
            this.listaGrupo = response.data
          }).catch(error => {
            this.$swal({
              icon: 'error',
              text: error,
            })
          })
        } catch (error) {
          this.$swal({
            icon: 'error',
            text: error,
          })
        }
      },
      async onPesquisaProduto () {
        try {
          if (this.validarPesquisa()) {
            this.$store.commit('setSplashScreen', true)
            this.carregarDados = false
            await produtoRep.getCode(this.loja, this.paramconsulta).then(response => {
              this.$store.commit('setSplashScreen', false)
              // console.warn(response.data.dados.nmItem)
              this.nmItem = response.data.dados.nmItem
              if (response.data.status === 'sucesso') {
                setTimeout(() => {
                  this.carregarDados = true
                }, 500)
              } else if (response.data.status === 'mensagem') {
                this.carregarDados = true
                this.$swal({
                  icon: 'warning',
                  text: response.data.messagem,
                })
              } else if (response.data.status === 'error') {
                this.carregarDados = true
                this.$swal({
                  icon: 'error',
                  text: response.data.messagem,
                })
              }
            }).catch(error => {
              this.$store.commit('setLogErro', error)
              this.carregarDados = true
              this.$store.commit('setSplashScreen', false)
              this.$swal({
                icon: 'error',
                // text: 'Erro ao consultar os Dados <br>' + error,
                html: 'Erro ao consultar os Dados </br>' + error,
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
                confirmButtonText:
                  '<i aria-hidden="true" class="v-icon notranslate white--text mdi mdi-check-outline theme--dark"></i> <strong>OK!</strong>',
              })
            })
          }
        } catch (error) {
          this.$store.commit('setLogErro', error)
          this.carregarDados = true
          this.$store.commit('setSplashScreen', false)
          this.$swal({
            icon: 'error',
            // text: 'Erro ao consultar os Dados <br>' + error,
            html: 'Erro ao consultar os Dados </br>' + error,
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
            focusConfirm: true,
          })
        }
      },
      async gerarPDF () {
        if (this.validarCampos()) {
          try {
            this.$store.commit('setSplashScreen', true)
            await posicaoFisicoRep.getEtiquetas(this.loja, this.cdLinha === 0 ? '' : this.cdLinha, this.cdGrupo === 0 ? '' : this.cdGrupo, this.paramconsulta === '' ? '' : this.paramconsulta).then(response => {
              this.urlbase = this.$store.getters.empresa.url.includes('locahost') ? this.$store.getters.empresa.url.replace('/Api/', '').replace('/api/', '') + '/temp/' + response.data.dados : this.$store.getters.empresa.url.replace('/sfcloud/Api/', '').replace('/sfcloud/api/', '') + '/temp/' + response.data.dados
              // this.nmpdf = response.data.dados
              window.location.href = this.urlbase
              // window.open(
              //   urlbase,
              //   '_blank', // <- This is what makes it open in a new window.
              // )

              // const reportBuffer = axios.get(urlbase, {
              //   responseType: 'arraybuffer',
              // })

              // const blob = new Blob([reportBuffer.data], { type: 'application/pdf' })
              // const link = document.createElement('a')
              // link.href = window.URL.createObjectURL(blob)
              // link.download = response.data.dados
              // link.click()
              // this.downloadFile(urlbase, response.data.dados)
              // axios({
              //   url: urlbase, // download file link goes here
              //   method: 'GET',
              //   responseType: 'blob',
              // }).then((res) => {
              //   var FILE = window.URL.createObjectURL(new Blob([res.data]))

              //   var docUrl = document.createElement('a')
              //   docUrl.href = FILE
              //   docUrl.setAttribute('download', response.data.dados)
              //   document.body.appendChild(docUrl)
              //   docUrl.click()
              // })

              this.$store.commit('setSplashScreen', false)
              // window.location.href = urlbase
            }).catch(error => {
              this.$swal({
                icon: 'error',
                text: error,
              })
            })
          } catch (error) {
            this.$swal({
              icon: 'error',
              text: error,
            })
          }

          // if (this.urlbase !== '') {
          //   await this.downloadFile()
          // }
        }
      },

      async downloadFile () {
        // console.log(this.urlbase)
        window.open(this.urlbase, '_blank')
        // await axios({
        //   url: this.urlbase, // download file link goes here
        //   method: 'GET',
        //   responseType: 'blob',
        // }).then((res) => {
        //   //   const blob = new Blob([res.data], { type: 'application/pdf' })
        //   //   const link = document.createElement('a')
        //   //   link.href = URL.createObjectURL(blob)
        //   //   link.download = this.nmpdf
        //   //   link.click()
        //   //   URL.revokeObjectURL(link.href)

        //   var FILE = window.URL.createObjectURL(new Blob([res.data]))
        //   var docUrl = document.createElement('a')
        //   docUrl.href = FILE
        //   docUrl.setAttribute('download', this.nmpdf)
        //   document.body.appendChild(docUrl)
        //   docUrl.click()
        // })

        // const reportBuffer = axios.get(this.urlbase, {
        //   responseType: 'blob',
        // })

        // const blob = new Blob([reportBuffer.data], { type: 'application/pdf' })
        // const link = document.createElement('a')
        // link.href = window.URL.createObjectURL(blob)
        // link.download = this.nmpdf
        // link.click()
      },
      onSelectLinha () {
        // console.log(this.cdLinha)
        this.getGrupo()
        this.flitem = false
      },
      onSelectLoja (loja) {
        this.loja = loja.cdLoja
      },
      onLimparDados () {
        this.loja = 0
        this.cdLinha = 0
        this.cdGrupo = 0
        this.flitem = false
        this.paramconsulta = ''
        this.nmItem = ''
        this.carregarDados = false
        setTimeout(() => {
          // this.$refs.txtcodigo.focus()
          this.carregarDados = true
        }, 1500)
      },
      validarCampos () {
        var flTemErro = false
        var mensagemErro = ''
        if (this.loja === 0) {
          flTemErro = true
          mensagemErro = 'Informe a Loja'
        }
        // if (this.flitem === false && this.cdLinha === 0) {
        //   flTemErro = true
        //   mensagemErro += '\n Informe a Linha'
        // }
        // if (this.flitem === true && this.paramconsulta === '') {
        //   flTemErro = true
        //   mensagemErro += '\n Informe o Item'
        // }

        if (flTemErro === true) {
          this.$swal({
            icon: 'warning',
            text: mensagemErro,
          })
          return false
        }
        return true
      },
      validarPesquisa () {
        if (this.loja === 0) {
          this.$swal({
            icon: 'warning',
            text: 'Informe a Loja',
          })
          this.nmItem = ''
          return false
        }
        if (this.paramconsulta === '' || this.paramconsulta === null) {
          this.$swal({
            icon: 'warning',
            text: 'Informe o Item',
          })
          this.nmItem = ''
          return false
        }
        return true
      },

    },
  }
</script>
