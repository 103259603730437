import Repository from './repository'

const resource = '/posicaofisico'

export default {

  getId (id) {
    return Repository.get(`${resource}/${id}`)
  },
  getList (id) {
    return Repository.get(`${resource}/getlist?loja=${id}`)
  },
  // https://localhost:44391/Api/posicaofisico/relatorio?linha=10&grupo=1027
  getEtiquetas (loja, linha, grupo, item) {
    return Repository.get(`${resource}/relatorio?loja=${loja}&linha=${linha}&grupo=${grupo}&item=${item}`)
  },
}
